import { BaseService } from '@/services/base.service';
import { RegionEditorService } from '@/services/modules/regionEditor.service';
import { promiseAll } from '@/utils/promiseAll';
import { init, getRegion, getManagingCompanyBySnapshotID } from './init';
import { getDefaultState } from './constants';

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setDefaultState(state) {
      const _defaultState = getDefaultState();
      Object.keys(_defaultState).forEach((key) => {
        state[key] = _defaultState[key];
      });
    },

    setState(state, partialState) {
      const partial = JSON.parse(JSON.stringify(partialState));
      Object.keys(partial).forEach((key) => {
        state[key] = partial[key];
      });
    },

    setRegion(state, data) {
      state.region = { ...data };
    },
    setLoadingRegion(state, data) {
      state.loadingRegion = data;
    },
    setSubregionsEnabled(state, data) {
      state.subregionsEnabled = data;
    },
    setCurrencyReporting(state, data) {
      state.currencyReporting = data;
    },
    setCurrencyRfa(state, data) {
      state.currencyRfa = data;
    },
    setFranchiseAgreement(state, data) {
      state.franchiseAgreement = data;
    },
    setCurrentDevelopmentScheduleSnapshot(state, data) {
      state.currentDevelopmentScheduleSnapshot = data;
    },
    setCurrentPerformanceScheduleSnapshot(state, data) {
      state.currentPerformanceScheduleSnapshot = data;
    },
    setSubregionTags(state, data) {
      state.subregionTags = data;
    },
    setSubregionDropdown(state, data) {
      state.subregionDropdown = data;
    },
    setManagingDirectors(state, data) {
      state.managingDirectors = data;
    },
    setManagingCompanySnapshot(state, data) {
      state.managingCompanySnapshot = data;
    },
    setLoadingManagingCompany(state, loading) {
      state.loadingManagingCompany = loading;
    },
    setCurrentManagingCompanySnapshotID(state, id) {
      state.currentManagingCompanySnapshot = id;
    },
    setManagingCompaniesSnapshots(state, { snapshotID, ...data }) {
      state.managingCompaniesSnapshots = {
        ...state.managingCompaniesSnapshots,
        [snapshotID]: {
          snapshotID,
          ...state.managingCompaniesSnapshots[snapshotID],
          ...data,
        },
      };
    },
    setRegionalPerson(state, data) {
      state.regionalPerson = data;
    },
    setFeeRelief(state, data) {
      state.feeRelief = data;
    },
    setLoadingFeeRelief(state, loading) {
      state.loadingFeeRelief = loading;
    },
    setFeeReliefFilters(state, data) {
      state.feeReliefFilters = data;
    },
    setFeeReliefTypes(state, data) {
      state.feeReliefTypes = data;
    },
    setAffectTypeOfFees(state, data) {
      state.affectTypeOfFees = data;
    },
    setCurrencyPayment(state, data) {
      state.currencyPayment = data;
    },
    setCurrencyLfa(state, data) {
      state.currencyLfa = data;
    },
    setDevelopmentSchedule(state, data) {
      state.developmentSchedule = data;
    },
    setPerformanceSchedule(state, data) {
      state.performanceSchedule = data;
    },
    setBusinessPlanLoading(state, data) {
      state.businessPlanLoading = data;
    },
    setBusinessPlanYear(state, data) {
      state.businessPlanYear = data;
    },
    setBusinessPlanYears(state, data) {
      state.businessPlanYears = data;
    },
    setNotes(state, data) {
      state.notes = { ...state.notes, ...data };
    },
    setHistory(state, data) {
      state.history = { ...state.history, ...data };
    },
    setBusinessPlan(state, data) {
      state.businessPlan = data;
    },
    setBusinessPlanV2(state, data) {
      state.businessPlanV2 = data;
    },
    setCountries(state, data) {
      state.countries = data;
    },
    setNextSubcountryIDs(state, data) {
      state.nextSubcountryIDs = data;
    },
    setPhonecodes(state, data) {
      state.phonecodes = data;
    },
    setRegionRoles(state, data) {
      state.regionRoles = data;
    },
    setCountryStates(state, data) {
      state.countryStates = { ...data };
    },
    setLoadingCountryStates(state, loading) {
      state.loadingCountryStates = loading;
    },
    setEmailCommunicationTags(state, data) {
      state.emailCommunicationTags = data;
    },
    setCurrencyDropdown(state, data) {
      state.currencyDropdown = data;
    },
    setFranchiseAgreementSegmentTypes(state, data) {
      state.franchiseAgreementSegmentTypes = data;
    },
    setNotesCategories(state, data) {
      state.notesCategories = data;
    },
    setCompanies(state, data) {
      state.companies = data;
    },
    setDevSnapshotList(state, data) {
      state.devSnapshotList = data;
    },
    setPerfSnapshotList(state, data) {
      state.perfSnapshotList = data;
    },
    setSubregionTagID(state, data) {
      state.subregionTagID = data || '';
    },
	setSubRegionInfo(state, data) {
		state.subRegionInfo = data || null;
	},
    setDevSchedule(state, data) {
      state.devSchedule = data;
    },
    setPerfSchedule(state, data) {
      state.perfSchedule = data;
    },
    appendDevelopmentSchedule(state, newRow) {
      state.devSchedule = [...state.devSchedule, newRow];
    },
    removeDevelopmentSchedule(state, index) {
      state.devSchedule = state.devSchedule.filter((_, i) => i !== Number(index));
    },
    appendPerformanceSchedule(state, newRow) {
      state.perfSchedule = [...state.perfSchedule, newRow];
    },
    removePerformanceSchedule(state, index) {
      state.perfSchedule = state.perfSchedule.filter((_, i) => i !== Number(index));
    },
    setPersonalCurrency(state, data) {
      state.personalCurrency = { isEnabled: data.isEnabled, currency: data.value };
    },
  },

  actions: {
    async getRegion(store, params) {
      store.commit('setLoadingRegion', true);
      const [getRegionRes] = await getRegion(store, params);
      store.commit('setLoadingRegion', false);
      if (getRegionRes?.error) throw getRegionRes.error;
      return getRegionRes;
    },

    async copyFranchiseAgreementToNewSubregion({ state }, params) {
      const res = await RegionEditorService.copyFranchiseAgreementToNewSubregion(state.region?.ID, params);
      return res;
    },

    async getManagingCompany(store, params) {
      store.commit('setLoadingManagingCompany', true);
      await getManagingCompanyBySnapshotID(store, params);
      store.commit('setLoadingManagingCompany', false);
    },

    async getManagingCompanySnapshots({ state, commit }, params) {
      const res = await RegionEditorService.managingCompanySnapshot(state.region?.ID, {
        ...(!state.subregionTagID ? {} : { 'meta[subregionTagID]': state.subregionTagID }),
        ...params,
      });
      commit('setManagingCompanySnapshot', res?.data?.result);
      return res;
    },

    async businessPlan({ commit, state }, params?: { year?: number; regionID?: number; }) {
      try {
        commit('setBusinessPlanLoading', true);

        const regionID = params?.regionID || state.region?.ID;
        const year = params?.year || state.businessPlanYear;

        const [businessPlan, businessPlanV2] = await Promise.all([
          RegionEditorService.businessPlan(regionID, {
            sort: 'month',
            'textFilter[year]': params?.year,
          }),
          RegionEditorService.businessPlanV2(regionID, year),
        ]);

        commit('setBusinessPlan', businessPlan?.data?.result);
        commit('setBusinessPlanV2', businessPlanV2);
        commit('setBusinessPlanYear', year);
      } catch(e: any) {
        throw e;
      } finally {
        commit('setBusinessPlanLoading', false);
      }
    },

    async getBusinessPlan({ commit, state }, params: any = {}) {
      const res = await RegionEditorService.businessPlan(state.region?.ID || params.region_id, {
        'textFilter[year]': state.businessPlanYear,
        ...params,
      });
      commit('setBusinessPlan', res?.data?.result);
      return res;
    },

    insertManagingCompanySnapshot({ state }, params) {
      return RegionEditorService.insertManagingCompanySnapshot(state.region?.ID, params);
    },

    updateManagingCompanySnapshot({ state }, { ID, ...params }) {
      return RegionEditorService.updateManagingCompanySnapshot(ID || state.currentManagingCompanySnapshot, params);
    },

    deleteManagingCompanySnapshot({ state }, ID) {
      return RegionEditorService.deleteManagingCompanySnapshot(ID || state.currentManagingCompanySnapshot);
    },

    async getFeeRelief({ state, commit }, _params: any = {}) {
      const { region_id, ...params } = _params;
      try {
        commit('setLoadingFeeRelief', true);
        const res = await RegionEditorService.feeRelief(region_id || state.region?.ID, params);
        commit('setFeeRelief', res?.data?.result);
        commit('setFeeReliefFilters', params);
        commit('setLoadingFeeRelief', false);
        return res;
      } catch (e) {
        commit('setLoadingFeeRelief', false);
        throw e;
      }
    },

    deleteFeeRelief(_, id) {
      return RegionEditorService.deleteFeeRelief(id);
    },

    saveFeeRelief({ state }, payload) {
      if (payload.ID) return RegionEditorService.updateFeeRelief(payload.ID, payload);
      return RegionEditorService.insertFeeRelief(state.region.ID, payload);
    },

    async getNotes({ commit, state }, { region_id, ...params }) {
      try {
        const rowsPerPage = params.rowsPerPage || state.notes.rowsPerPage || 35;
        let currentPage = params.currentPage || state.notes.currentPage || 1;

        const res = await RegionEditorService.getNotes(region_id || state?.region?.ID, {
          ...params,
          limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setNotes', {
          list: res?.data?.result || [],
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });

        return res;
      } catch (e) {
        throw e;
      }
    },

    async saveNote({ commit }, { region_id, entity }) {
      try {
        let res = null;
        if (entity.ID) {
          res = await RegionEditorService.updateNote(entity, region_id);
        } else {
          res = await RegionEditorService.insertNote(entity, region_id);
        }
        return res;
      } catch (e) {
        throw e;
      }
    },

    async deleteNote({ commit }, { note_id, region_id }) {
      try {
        const res = await RegionEditorService.deleteNote(note_id);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async getHistory({ commit, state }, { region_id, ...params }) {
      try {
        const rowsPerPage = params.rowsPerPage || state.history.rowsPerPage || 35;
        let currentPage = params.currentPage || state.history.currentPage || 1;

        const res = await RegionEditorService.history(region_id || state?.region?.ID, {
          ...params,
          limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        });

        const totalRows = Number(res?.data?.info?.numRows || '0');
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        currentPage = currentPage > totalPages ? totalPages : currentPage;

        commit('setHistory', {
          list: res?.data?.result || [],
          totalRows,
          rowsPerPage,
          currentPage,
          totalPages,
        });

        return res;
      } catch (e) {
        throw e;
      }
    },

    async getCountryStates({ commit }, countryISO) {
      try {
        commit('setLoadingCountryStates', true);
        const res = await RegionEditorService.countryStates(countryISO);
        commit('setCountryStates', { [countryISO]: res?.data?.result || [] });
        commit('setLoadingCountryStates', false);
        return res;
      } catch (e) {
        commit('setLoadingCountryStates', false);
        throw e;
      }
    },

    async getCompanies({ commit }, params) {
      try {
        const res = await RegionEditorService.getCompanies(params);
        commit('setCompanies', res?.data?.result || []);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async save({ state }, { region, franchiseAgreement, perfSchedule, devSchedule, businessPlanV2 }) {
      try {

    	let businessPlanV2Promise = null;
		let regionPromise = null;
		let franchiseAgreementPromise = null; 		
		let devSchedulePromise = null;
		let perfSchedulePromise = null; 
		const otherPromises = [];

		if (state.region?.ID) {
			if (region) regionPromise = BaseService.put(`region/${state.region?.ID}`, region);
			// if (franchiseAgreement) franchiseAgreementPromise = BaseService.post(`region/${state.region?.ID}/franchiseAgreement`, franchiseAgreement);
			if (franchiseAgreement) {
				const allSegments = (state.franchiseAgreement.franchiseFeeStructures || []).filter(s => s.id);
				const deletedSegments = allSegments.filter(s => !franchiseAgreement.franchiseFeeStructures.map(s => `${s.id}`).includes(`${s.id}`));
				if (state.subRegionInfo) {
					franchiseAgreementPromise = BaseService.put('v1/subRegion/franchiseAgreement', {
						regionID: state.region?.ID,
						subRegionID: state.subRegionInfo.id,
						franchiseAgreement,
					});
					deletedSegments.forEach(s => otherPromises.push(BaseService.delete(`v1/subRegion/franchiseAgreement/removeFeeStructure?regionID=${state.region?.ID}&feeStructureToRemoveID=${s.id}&subRegionID=${state.subRegionInfo.id}`)));
					allSegments.forEach(s => {
						const updatedSegment = franchiseAgreement.franchiseFeeStructures.filter(fs => `${fs.id}` === `${s.id}`)[0];
						s.localMonthlyContinuingFees.filter(y => y.id).forEach(y => {
							const shouldDelete = !updatedSegment ? true : !updatedSegment.localMonthlyContinuingFees.map(y => `${y.id}`).includes(`${y.id}`);
							if (shouldDelete) otherPromises.push(BaseService.delete(`v1/subRegion/franchiseAgreement/feeStructure/removeMonthlyContinuingFee?regionID=${state.region?.ID}&id=${y.id}&subRegionID=${state.subRegionInfo.id}`))
						});
						s.localMonthlyPromotionAndDevelopmentFees.filter(y => y.id).forEach(y => {
							const shouldDelete = !updatedSegment ? true : !updatedSegment.localMonthlyPromotionAndDevelopmentFees.map(y => `${y.id}`).includes(`${y.id}`);
							if (shouldDelete) otherPromises.push(BaseService.delete(`v1/subRegion/franchiseAgreement/removeMonthlyPromotionAndDevelopmentFee?regionID=${state.region?.ID}&id=${y.id}&subRegionID=${state.subRegionInfo.id}`))
						});
					});	
				} else {
					franchiseAgreementPromise = BaseService.put('v1/region/save', {
						regionID: state.region?.ID,
						...state.region,
						regionalFranchiseAgreement: franchiseAgreement,
					});
					deletedSegments.forEach(s => otherPromises.push(BaseService.delete(`v1/region/franchiseAgreement/removeFeeStructure?regionID=${state.region?.ID}&feeStructureToRemoveID=${s.id}`)));
					allSegments.forEach(s => {
						const updatedSegment = franchiseAgreement.franchiseFeeStructures.filter(fs => `${fs.id}` === `${s.id}`)[0];
						s.localMonthlyContinuingFees.filter(y => y.id).forEach(y => {
							const shouldDelete = !updatedSegment ? true : !updatedSegment.localMonthlyContinuingFees.map(y => `${y.id}`).includes(`${y.id}`);
							if (shouldDelete) otherPromises.push(BaseService.delete(`v1/region/franchiseAgreement/feeStructure/removeMonthlyContinuingFee?regionID=${state.region?.ID}&id=${y.id}`))
						});
						s.localMonthlyPromotionAndDevelopmentFees.filter(y => y.id).forEach(y => {
							const shouldDelete = !updatedSegment ? true : !updatedSegment.localMonthlyPromotionAndDevelopmentFees.map(y => `${y.id}`).includes(`${y.id}`);
							if (shouldDelete) otherPromises.push(BaseService.delete(`v1/region/franchiseAgreement/removeMonthlyPromotionAndDevelopmentFee?regionID=${state.region?.ID}&id=${y.id}`))
						});
					});	
				}
			}
			if (devSchedule) devSchedulePromise = BaseService.post(`region/${state.region?.ID}/developmentScheduleSnapshot`, devSchedule);
			if (perfSchedule) perfSchedulePromise = BaseService.post(`region/${state.region?.ID}/performanceScheduleSnapshot`, perfSchedule);

      if(businessPlanV2){
        businessPlanV2Promise = RegionEditorService.updateBusinessPlanV2(businessPlanV2, state.region?.ID);
      }
		} else {
			if (region) regionPromise = BaseService.post('regions', region);
		}

        const res = await promiseAll([
			regionPromise,
			franchiseAgreementPromise,
			devSchedulePromise,
			...otherPromises
        ]);
		
        return {
          saveRegionResponse: res[0],
          saveFranchiseAgreementResponse: res[1],
          saveDevScheduleResponse: res[2],
          savePerfScheduleResponse: res[3],
          businessPlanV2Response : businessPlanV2Promise
        };
      } catch (e) {
        throw e;
      }
    },

    init(store, region_id) {
      return init(store, region_id);
    },

    async loadEditorRequirements(store, region_id) {
      try {
        return await init(store, region_id);
      } catch (e) {
        throw e;
      }
    },

    async getDevelopmentScheduleBySnapshot({ state, commit }, snapshotID) {
      let list = [];
      snapshotID = snapshotID || '-1';
      if (snapshotID !== '-1') {
        try {
          commit('setState', { devSchedule: { ...getDefaultState().devSchedule, snapshotID, list, loading: true } });
          const res = await RegionEditorService.getDevelopmentScheduleBySnapshot(snapshotID);
          list = res?.data?.result?.developmentSchedules || [];
          commit('setState', { devSchedule: { ...state.devSchedule, list, loading: false } });
        } catch (e) {
          commit('setState', { devSchedule: { ...state.devSchedule, loading: false } });
        }
      } else {
        commit('setState', { devSchedule: state.todaysDevSchedule });
      }
    },

    async getPerformanceScheduleBySnapshot({ state, commit }, snapshotID) {
      try {
        let list = [];
        snapshotID = snapshotID || '-1';
        commit('setState', { perfSchedule: { ...getDefaultState().perfSchedule, snapshotID, list, loading: true } });
        if (snapshotID !== '-1') {
          const res = await RegionEditorService.getPerformanceScheduleBySnapshot(snapshotID);
          list = res?.data?.result?.performanceSchedules || [];
        }
        commit('setState', { perfSchedule: { ...state.perfSchedule, list, loading: false } });
      } catch (e) {
        commit('setState', { perfSchedule: { ...state.perfSchedule, loading: false } });
      }
    },

    async getPerformanceSnapShotList({ state, commit }) {
      return RegionEditorService.getPerformanceSnapShotList(state.currentPerformanceScheduleSnapshot.ID ?? -1).then((res) =>
        commit('setDevSchedule', res.data.result.performanceSchedules)
      );
    },
    async insertDevelopmentSchedules({ state, commit }, id) {
      return RegionEditorService.insertDevelopmentSchedules(
        state.devSchedule,
        id,
        state.currentDevelopmentScheduleSnapshot.validFrom
      );
    },
    async insertPerformanceSchedules({ state, commit }, id) {
      return RegionEditorService.insertPerformanceSchedules(
        state.perfSchedule,
        id,
        state.currentPerformanceScheduleSnapshot.validFrom
      );
    },
    saveRegionalPerson({ state }, { region_id, entity }) {
      if (entity.ID) {
        return RegionEditorService.updateRegionalPersons(entity.ID, entity);
      } else {
        return RegionEditorService.insertRegionalPersons(region_id || state.region?.ID, entity);
      }
    },
    changeTitleInOffice({ state, commit }, { id, entity }) {
      return RegionEditorService.changeTitleInOffice(id, entity);
    },
    endTitleInOffice({ state, commit }, { id, entity }) {
      return RegionEditorService.endTitleInOffice(id, entity);
    },
    async regionalPerson({ state, commit }, _params: any = {}) {
      const { region_id, ...params } = _params;
      const filters = { ...state.regionalPersonFilters, ...params };
      try {
        const res = await RegionEditorService.regionalPerson(region_id || state?.region?.ID, filters);
        commit('setState', {
          regionalPersonFilters: filters,
          regionalPerson: res?.data?.result || [],
        });
        return res;
      } catch (e) {
        throw e;
      }
    },
    async isPrimaryTitle({ state, commit }, id) {
      const res = await RegionEditorService.isPrimaryTitle(id);
      return res;
    },
    async getPrimaryTitleList({ state, commit }, { personID, relationID }) {
      const res = await RegionEditorService.getPrimaryTitleList(personID, relationID);
      return res;
    },
    async CheckReportingPeriodStatus(_, { regionID, period }) {
      const res = await RegionEditorService.CheckReportingPeriodStatus(regionID, period);
      return res.data.result;
    },

    async overrideOfficeContracts({ state, commit }, id) {
      try {
        const res = await RegionEditorService.overrideOfficeContracts(id);
        return res;
      } catch (e) {
        throw e;
      }
    },

    async saveAnnualDuesForAgreement({ state, commit }, params) {
      try {
        console.log('here');
        const res = await RegionEditorService.saveAnnualDuesForAgreement(params);
        return res;
      } catch (e) {
        throw e;
      }
    },
  },
};
